import { connect, ConnectedProps } from "react-redux"
import { actions } from "../redux/actions"
import { RootState } from "../redux/store"
import { RouteComponentProps } from "@reach/router"
import queryString from "query-string"

const mapState = (state: RootState) => ({})

const mapDispatch = (dispatch: any, props: RouteComponentProps) => ({
  onMount: () => {
    dispatch(actions.editor.checkIfEditorShouldBeReset({ from: "editor" }))

    dispatch(actions.auth.fetchIsAuthenticated()).then(() => {
      const search = queryString.parse(props.location.search)

      dispatch(
        actions.editor.getHaikuAndStartEdition({
          id: search?.id as string | null,
        })
      )
    })
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
