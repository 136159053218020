import React, { useEffect } from "react"
import { RouteComponentProps } from "@reach/router"

import * as Editor from "../components/Editor"
import { Seo } from "../components/Seo/Seo"
import { connector, ContainerProps } from "../containers/editer.container"

type Props = {
  onMount: Function
} & RouteComponentProps

const EditerWrapper: React.FC<Props> = props => {
  useEffect(() => {
    props.onMount()
  }, [])

  const from = props.location.state?.from || null

  return (
    <>
      <Seo index={false} title="Éditer son haïku | Temple du Haïku" />
      <div className="min-h-screen bg-gray-50">
        <Editor.Header from={from} />
        <Editor.Keyboard />
        <Editor.Indicator />
        <Editor.Loader />
        <Editor.Category />
      </div>
    </>
  )
}

export const EditerContainer: React.FC<
  ContainerProps & RouteComponentProps
> = props => <EditerWrapper {...props} />

export default connector(EditerContainer)
